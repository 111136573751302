import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import React, { useEffect, useState } from 'react'
import './videoHeader.css'
import aboutVideo from "./videos/homepageVideo.mp4"
import { useNavigate } from 'react-router-dom'
import thumbnail from "../src/photos/homepageVideoThumbnail.jpg"

//genericka klasa, content cemo menjati preko props
const VideoHeader = ({ title, buttonText1, buttonText2, myRef }) => {

  const navigate = useNavigate();

  const handleScroll = () =>
  {
    myRef.current.scrollIntoView()
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const content = useTheme({
    alignItems:'center', 
    justifyContent:'center', 
    width:'100%', 
    height:'100%',
    color:'white', 
    position:'absolute', 
    paddingTop:'0px',
    paddingBottom:'0px',
    paddingLeft:'10px',
    paddingRight:'10px',
    top:'22px', 
    display:'flex', 
    flexDirection:'column'
  });

  const overlay = useTheme({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.5)'
  })

  const spacing = windowSize.innerWidth<550?3:5;

  return (
        <div style={{width:'100%', height:'800px', position:'relative', backgroundColor:'aliceblue'}}>
            <div style={overlay}></div>
            <video preload='auto' autoPlay loop muted poster={thumbnail} style={{width:'100%', objectFit:'cover', top:'0', height:'800px'}}>
              <source src={aboutVideo} type="video/mp4"></source>

            </video>
            <div style={content}>
                <Typography
                  variant='h1'
                  component="p"
                  style={{textAlign:'center', display:'block', marginBottom:'30px'}}
                  sx={{
                    fontFamily: 'inherit',
                    fontSize:'50px',
                    fontWeight: 900,
                    letterSpacing: '.2rem',
                    color: 'inherit',
                    textDecoration: 'none'}}
                >
                {title}
                </Typography>
                  <Grid container spacing={spacing} style={{justifyContent:'center', alignItems:'center', marginTop:'50px'}}>
                      <Grid item sx={'auto'}>
                        <button className="videoButton learnButton" onClick={handleScroll}>{buttonText1}</button>
                      </Grid>
                      <Grid item sx={'auto'}>
                        <button className="videoButton contactButton" onClick={() => navigate('/contact')}>{buttonText2}</button>
                      </Grid>
                  </Grid>
            </div>
        </div>
  )
}

export default VideoHeader