import React from 'react'
import './header.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SchoolIcon from '@mui/icons-material/School';
import Grid from '@mui/material/Grid';
import ShareDialog from './pages/application/ShareDialog';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//genericka klasa, content cemo menjati preko props
const Header = ({ title, text, buttonText, isApplicationPage, buttonLink, setCurrentPage, myRef }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const handleScroll = () =>
  {
    if(myRef === undefined || myRef === null)
      window.scrollTo(100, 500);	
    else
      myRef.current.scrollIntoView()
  }
  
  return (
    <div>
        {buttonText !== 'none' && <div className="header-wrapper">
            {!isApplicationPage && !location.pathname.startsWith("/careers") && <div className="title">
                <h1>{title}</h1>
            </div>}
            {isApplicationPage && <div className="title">
                <h2>{title}</h2>
            </div>}
            {location.pathname.startsWith("/careers") && <div className="title">
                <h1>{title}</h1>
            </div>}
            <div className="text">
              {isApplicationPage && <h4>
                <Grid container spacing={6} classname='grid-container' style={{
                  width:'150%',
                  justifySelf:'center',
                  marginLeft:'-22%',
                  textAlign:'center',
                  marginTop:'-80px',
                  marginBottom:'30px',
                  gridTemplateRows: 'auto',
                  gridTemplateColumns: 'auto'
                  }}
                  display={{xs:'grid', md:"flex", lg:'flex'}}
                  justifyItems={{xs:'start', sm:'center', md:'center'}}>
                  <Grid item xs={4} marginLeft={{xs:'-20%', sm:'-10%', md:'0%', lg:'0%'}}>
                    <span className='location-icon-wrapper'>
                        <LocationOnIcon style={{marginBottom: '7px', 
                          marginRight: '7px'}}>
                        </LocationOnIcon> 
                    </span>{text.location}  
                  </Grid>
                  <Grid item xs={3} marginLeft={{xs:'-20%', sm:'-10%', md:'0%', lg:'0%'}}>
                    <span className='location-icon-wrapper'>
                        <AccessTimeIcon style={{marginBottom: '7px', 
                          marginRight: '7px'}}>
                        </AccessTimeIcon> 
                    </span>{text.workingTime}
                  </Grid>
                  <Grid item xs={4} marginLeft={{xs:'-20%', sm:'-10%', md:'0%', lg:'0%'}}>
                    <span className='location-icon-wrapper'>
                        <SchoolIcon style={{marginBottom: '7px', 
                          marginRight: '7px'}}>
                        </SchoolIcon> 
                    </span>{text.workingExperience}
                  </Grid>
                </Grid>
              </h4>}
              {!isApplicationPage && <h4>{text}</h4>}
              {location.pathname.startsWith("/careers") && <h4 style={{ marginTop:'-7%'}}>This is where knowledge and imagination gather together, committed to the values that lead to excellence.  Join the <a style={{color:'blue', cursor:'pointer', textDecoration:'none'}} href='/our%20team'>Sparrow team</a> to solve problems and help make a difference. If you are interested in exploring a career opportunity at Sparrow, apply directly to one of our postings at <a style={{color:'blue', cursor:'pointer'}} onClick={handleScroll}>"Current Opportunities"</a></h4>}
            </div>

            {!isApplicationPage && buttonText !== 'none' && <button className="header-button" 
                                      onClick={()=>{if (buttonLink===undefined)
                                                      handleScroll()
                                                    else{
                                                      navigate(buttonLink); 
                                                      setCurrentPage(buttonLink)
                                                    }
                                                    }}>{buttonText}</button>}
            {isApplicationPage && 
            <ShareDialog className="header-button" buttonText={buttonText}>
            </ShareDialog>}
        </div>}
          
        {/* slucaj kada imamo contact page header */}

        {buttonText === 'none' && <div className="header-wrapper-contact">
            {!isApplicationPage && !location.pathname.startsWith("/careers") && <div className="title">
                <h1>{title}</h1>
            </div>}
            {isApplicationPage && <div className="title">
                <h2>{title}</h2>
            </div>}
            {location.pathname.startsWith("/careers") && <div className="title">
                <h1>{title}</h1>
            </div>}
            <div className="text">
              {isApplicationPage && <h4>
                <Grid container spacing={6} classname='grid-container' style={{
                  width:'150%',
                  justifySelf:'center',
                  marginLeft:'-22%',
                  textAlign:'center',
                  marginTop:'-80px',
                  marginBottom:'30px',
                  gridTemplateRows: 'auto',
                  gridTemplateColumns: 'auto'
                  }}
                  display={{xs:'grid', md:"flex", lg:'flex'}}
                  justifyItems={{xs:'start', sm:'center', md:'center'}}>
                  <Grid item xs={4} marginLeft={{xs:'-20%', sm:'-10%', md:'0%', lg:'0%'}}>
                    <span className='location-icon-wrapper'>
                        <LocationOnIcon style={{marginBottom: '7px', 
                          marginRight: '7px'}}>
                        </LocationOnIcon> 
                    </span>{text.location}  
                  </Grid>
                  <Grid item xs={3} marginLeft={{xs:'-20%', sm:'-10%', md:'0%', lg:'0%'}}>
                    <span className='location-icon-wrapper'>
                        <AccessTimeIcon style={{marginBottom: '7px', 
                          marginRight: '7px'}}>
                        </AccessTimeIcon> 
                    </span>{text.workingTime}
                  </Grid>
                  <Grid item xs={4} marginLeft={{xs:'-20%', sm:'-10%', md:'0%', lg:'0%'}}>
                    <span className='location-icon-wrapper'>
                        <SchoolIcon style={{marginBottom: '7px', 
                          marginRight: '7px'}}>
                        </SchoolIcon> 
                    </span>{text.workingExperience}
                  </Grid>
                </Grid>
              </h4>}
              {!isApplicationPage && <h4>{text}</h4>}
              {location.pathname.startsWith("/careers") && <h4 style={{ marginTop:'-7%'}}>This is where knowledge and imagination gather together, committed to the values that lead to excellence.  Join the <a style={{color:'blue', cursor:'pointer', textDecoration:'none'}} href='/our%20team'>Sparrow team</a> to solve problems and help make a difference. If you are interested in exploring a career opportunity at Sparrow, apply directly to one of our postings at <a style={{color:'blue', cursor:'pointer'}} onClick={handleScroll}>"Current Opportunities"</a></h4>}
            </div>

            {!isApplicationPage && buttonText !== 'none' && <button className="header-button" 
                                      onClick={()=>{if (buttonLink===undefined)
                                                      handleScroll()
                                                    else{
                                                      navigate(buttonLink); 
                                                      setCurrentPage(buttonLink)
                                                    }
                                                    }}>{buttonText}</button>}
            {isApplicationPage && 
            <ShareDialog className="header-button" buttonText={buttonText}>
            </ShareDialog>}
        </div>}
    </div>
  )
}

export default Header