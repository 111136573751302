import React from 'react'
import { useRef, useEffect } from 'react'
import { useParams} from 'react-router-dom'
import './careerPage.css'
import ApplicationPage from './application/ApplicationPage'

const CareersPage = () => {

  const {onlyCareerClicked} = useParams(); 

  useEffect(() => {
    if(onlyCareerClicked === 'true')
    {
      window.scrollTo(0, 0)
    }
  }, [])

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView() 

  return (
    <div style={{backgroundColor: 'white'}}>
      <div className='career-header'>
        <h2 className='career-header-title'>Careers</h2>
        <div className='career-header-text'>This is where knowledge and imagination gather together, committed to the values that lead to excellence.  Join the Sparrow team to solve problems and help make a difference. If you are interested in exploring a career opportunity at Sparrow, tell us about yourself below.
        <div className='career-header-button-wrapper'>
          <button className='career-header-button' onClick={executeScroll}>JOIN THE FLOCK</button>
        </div>
        </div>
      </div>
      <div style={{scrollMarginTop:"85px"}} ref={myRef}></div>
      <br />
      <ApplicationPage/>
    </div>
  )
}

export default CareersPage