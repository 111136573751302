import { Dialog, DialogContent, Grid, IconButton} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import parse from 'html-react-parser'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const SolutionsDialog = ({openDialog, setOpenDialog, title}) => {

    const [selectedTitle, setSelectedTitle] = useState("");
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }

    useEffect(() => {
      setSelectedTitle(title);
    }, [title])

    
    

    const content = [
        {
            title:"Full Stack Development",
            text:"Sparrow Remote Solutions offers a wide variety of tech-based services, namely, experienced and talented full stack developers. Our team of full stack developers offer integrated web development solutions - encompassing both development domains: the front end and back end. <br/><br/>Our team of full stack JavaScript developers develop entire applications using JavaScript and JavaScript-based technologies. Moreover, Sparrow’s developers have ample experience in programing UI using Angular or React, server-side using Node. js or Express. js, and using MongoDB as the database."
        },
        {
            title:"3D Animation",
            text:"Sparrow’s three-dimensional animation solutions are among the best in the industry. Our team of immensely talented 3D animators have worked on renowned projects such as the Call of Duty franchise, Witcher, FIFA Sports, Cartoon Network, and Netflix Exclusives. <br/><br/> Our 3D animators are adept at using both open-source animation software such as Blender, Autodesk Maya, and Cinema 4D, as well as various custom tools and softwares depending on our client’s needs."
        },
        {
            title:"Graphic Design",
            text:"Our graphic designers cover a broad range of expertise including product, branding, animation, print, and website design. Every industry and every client has their own set of design needs. At Sparrow Remote Solutions, we provide graphic designers whose knowledge and experience sits at the cross-section between art, technology, and communication."
        },
        {
            title:"DevOps",
            text:"Sparrow’s DevOps engineers introduce processes, tools, and methodologies to balance needs throughout the software development life cycle, from coding and deployment, to maintenance and updates. Our team of DevOps specialists bridge the gap between development and operations, while providing a high level of industry knowledge and expertise."
        },
        {
            title:"UI Design",
            text:"Our UI designers prioritize the user’s visual experience. At Sparrow, our UI team is obsessed with upholding three key principles of the craft – functionality, reliability, and enjoyability. Our UI designers add value through minimizing the effort needed to be invested by users when interacting with a product. Intuitive design drives adoption, and Sparrow’s team of professional UI designers are masters of their craft."
        },
        {
            title:"UX Design",
            text:"Sparrow’s dedicated UX designers adopt a ‘human-first approach’ with regards to product design. Our commitment to quality ensures that user pain points are eliminated through effective structural design. Sparrow’s dedicated UX designers drive user delight through efficient,  relevant, and overall pleasant user experience design."
        }
    ]

    function nextSolution(offset){
        let index = content.map(e => e.title).indexOf(selectedTitle);
        let nextIndex = 0;
        if(index == 0 && offset == -1)
            nextIndex = content.length-1;
        else if(index == content.length-1 && offset == 1)
            nextIndex = 0;
        else
            nextIndex = index + offset;

        console.log(nextIndex);
        setSelectedTitle(content[nextIndex].title);

    }

    useEffect(()=>{
        let arrowWidth = 1;
        let textWidth = 10;
        let arrowMt = 150;

        if(windowSize.innerWidth<585){
            arrowWidth = 1.5;
            textWidth = 9;
            arrowMt = 200;
        }
        if(windowSize.innerWidth<430){
            arrowWidth = 2;
            textWidth = 8;
            arrowMt = 250;
        }
        if(windowSize.innerWidth<320){
            arrowWidth = 2.2;
            textWidth = 7.6;
            arrowMt = 300;
        }

        setArrowWidth(arrowWidth);
        setTextWidth(textWidth);
        setArrowMT(arrowMt);
      },[windowSize.innerWidth]);
    
      const [arrowWidth, setArrowWidth] = useState(1);
      const [textWidth, setTextWidth] = useState(10);
      const [arrowMT, setArrowMT] = useState(150);


  return (
    <Dialog open={openDialog} onClose={()=>{setOpenDialog(false);}} style={{backgroundColor:'rgba(0,0,0,.7)',backdropFilter:'blur(5px)'}} PaperProps={{style:{borderRadius:'50px'}}} fullWidth={true} maxWidth={'md'}>
        <DialogContent style={{paddingRight:'0px', paddingLeft: '0px', width:"100%"}}>
          <Grid container style={{justifyContent:'center', padding:"0px", width:'100%'}}>
            <Grid item xs={arrowWidth} >
                <div style={{marginTop:arrowMT+"px"}}>
                    <IconButton onClick={()=>nextSolution(-1)} style={{paddingLeft:windowSize.innerWidth>720?"10px":0}}>
                        <ArrowCircleLeftOutlinedIcon style={{fontSize:'45'}}/>
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={textWidth} zeroMinWidth>

                <div style={{width:'100%', margin:'0 auto'}}>
                    <h2 style={{marginBottom:'30px'}}>{selectedTitle}</h2>
                    <p>{content.find(x=>x.title === selectedTitle)!==undefined && parse(content.find(x=>x.title === selectedTitle).text)}</p>
                </div>
            </Grid>
            <Grid item xs={arrowWidth}>
                <div>
                    <IconButton onClick={()=>setOpenDialog(false)} style={{paddingLeft:"0px"}}>
                        <CloseRoundedIcon style={{fontSize:'30'}} ></CloseRoundedIcon>
                    </IconButton>
                </div>
                <div style={{marginTop:(arrowMT-45)+"px"}}>
                    <IconButton onClick={()=>nextSolution(1)} style={{paddingLeft:"0px"}}>
                        <ArrowCircleRightOutlinedIcon style={{fontSize:'45'}}/>
                    </IconButton>
                </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  )
}

export default SolutionsDialog