import { Box, Dialog, DialogContent,  Grid, IconButton } from '@mui/material';
import React, { useRef } from 'react'
import { useEffect } from 'react';
import { managementTeam } from '../data/managementTeam';
import { teamLeads } from '../data/teamLeads';
import './OurTeam.css'
import { useState } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import parse from 'html-react-parser'

const OurTeamPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const myRef = useRef(null);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const handleScroll = () =>
  {
    myRef.current.scrollIntoView()
  }

  const headerText = "Meet the team making Sparrow’s mission a reality. Our flock of sparrows is talented, diverse, and experienced in their respective fields. The team is constantly growing and innovating - providing new talent, innovative ideas and practical solutions for our clients. ";

  const ReadMore = (member)=>{
    console.log("nesto");
    setOpenDialog(true);
    setSelectedMember(member);
  }

  const changeMember = (offset)=>{
    let index = managementTeam.map(e => e.nameAndSurname).indexOf(selectedMember.nameAndSurname);
    let team = managementTeam;
    if(index == -1){
      index = teamLeads.map(e => e.nameAndSurname).indexOf(selectedMember.nameAndSurname);
      team = teamLeads;
    }
    let nextIndex = 0;
    if(index == 0 && offset == -1)
      nextIndex = team.length-1;
    else if(index == team.length-1 && offset == 1)
      nextIndex = 0;
    else
      nextIndex = index + offset;
    setSelectedMember(team[nextIndex]);
  }

  useEffect(()=>{
    let width = "100%";
    if(windowSize.innerWidth<900)
      width = "95%";
    if(windowSize.innerWidth<570)
      width = "90%";
    if(windowSize.innerWidth<400)
      width = "85%";
    if(windowSize.innerWidth<320)
      width = "80%";
    
    
    setDialogContentWidth(width);
  },[windowSize.innerWidth]);

  const [dialogContentWidth, setDialogContentWidth] = useState("100%");

  const spacingManagement = windowSize.innerWidth>762?(windowSize.innerWidth/100-2):5;
  const spacingLeads = windowSize.innerWidth>1160?(windowSize.innerWidth/180):spacingManagement;

  return (
    <>
      
      <Dialog open={openDialog} onClose={()=>{setOpenDialog(false);}} style={{backgroundColor:'rgba(0,0,0,.7)',backdropFilter:'blur(5px)'}} PaperProps={{style:{borderRadius:'50px'}}} fullWidth={true} maxWidth={'lg'}>
        <DialogContent style={{paddingRight:'0px', paddingLeft: '0px'}}>
          <Grid container style={{justifyContent:'center', padding:"0px", width:dialogContentWidth}}>
            <Grid item xs={11.5} md={4}>
              <img alt='portrait' style={{width:'100%', borderRadius:'50px', margin:'0px'}} src={selectedMember.picture}></img>
            </Grid>
            {windowSize.innerWidth<900 && 
              <Grid item xs={0.5}>
                <IconButton onClick={()=>setOpenDialog(false)} style={{marginLeft:windowSize.innerWidth<900?'0px' : '15px'}}>
                  <CloseRoundedIcon style={{fontSize:'30'}} ></CloseRoundedIcon>
                </IconButton>
              </Grid>
            }
            <Grid item xs={1} md={0.5}>
              <IconButton style={{marginTop:'170px', color:'black', paddingLeft:'0px'}} onClick={()=>changeMember(-1)}>
                <ArrowCircleLeftOutlinedIcon style={{fontSize:windowSize.innerWidth>340?'40':'30'}} ></ArrowCircleLeftOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={10} md={6}>
                <Box style={{textAlign:'left'}} >
                <h4 style={{fontWeight:'800', marginBottom:'0px', marginLeft:'15px', padding:'0px', paddingTop:windowSize.innerWidth<900?"50px":"0px", textAlign:'left', fontSize:'23px'}}>{selectedMember.nameAndSurname}</h4>
                <h5 style={{fontWeight:'800', marginTop:'0px', marginLeft:'15px', fontSize:'19px'}}>{selectedMember.role}</h5>
              </Box>
              <p style={{paddingLeft:'20px', fontSize:'16px'}}>{selectedMember.bio!==undefined && parse(selectedMember.bio)}</p>
            </Grid>
            <Grid item xs={1} md={0.5}>
              {windowSize.innerWidth>900 && <IconButton onClick={()=>setOpenDialog(false)} style={{marginLeft:windowSize.innerWidth<900?'0px' : '15px'}}>
                <CloseRoundedIcon style={{fontSize:'30'}} ></CloseRoundedIcon>
              </IconButton>}
              <IconButton style={{marginTop:windowSize.innerWidth<=900?'165px':'122px', marginLeft:windowSize.innerWidth<900?'0px' : '10px', color:'black'}} onClick={()=>changeMember(1)}>
                <ArrowCircleRightOutlinedIcon style={{fontSize:windowSize.innerWidth>340?'40':'30'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <div className='otHeaderBackground'></div>
        <div className='otHeaderContent'>
          <h1>Our Team</h1>
          <h3>
            {headerText}
          </h3>
          <button className='otHButton' onClick={handleScroll}>LEARN MORE</button>
        </div>
        <div className="containterDiv" ref={myRef} >
          <h2>Management Team</h2>
        </div>
        <div style={{marginBottom:'30px'}}>
          <Grid container spacing={spacingManagement} style={{justifyContent:'center', alignItems:'center'}}>
            {
              managementTeam.map((member)=>(
                <Grid item xs={'auto'}>
                  <div className='PersonCard' onClick={()=>{ReadMore(member)}}>
                    <img alt="profile" src={member.picture}></img>
                    <div>
                      <h4>{member.nameAndSurname}</h4>
                      <h5>{member.role}</h5>
                      <p>{member.shortBio}</p>
                      <a href='' onClick={(e)=>{e.preventDefault()}}>Read more [+]</a>
                    </div>
                  </div>
                </Grid>
              ))
            }
          </Grid>
          </div>
          <div className="containterDiv">
          <h2>Team Leads</h2>
          </div>
          <div style={{marginBottom:'80px'}}>
          <Grid container spacing={spacingLeads} style={{justifyContent:'center', alignItems:'center'}}>
            {
              teamLeads.map((member)=>(
                <Grid item xs={'auto'}>
                  <div className='PersonCard' onClick={()=>{ReadMore(member)}}>
                    <img alt="profile" src={member.picture}></img>
                    <div>
                      <h4>{member.nameAndSurname}</h4>
                      <h5>{member.role}</h5>
                      <p>{member.shortBio}</p>
                      <a href='' onClick={(e)=>{e.preventDefault()}}>Read more [+]</a>
                    </div>
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </div>
    </>
  )
}

export default OurTeamPage