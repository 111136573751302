export const managementTeam=[
    {
    nameAndSurname:'Bojan Vlajnic',
    role:"CEO & President",
    picture:"photos/team/Bojan.jpg",
    shortBio:"Bojan, co-founder of Sparrow Remote Solutions, is responsible for overseeing Sparrow’s operations and growth, finding....",
    bio:"Bojan, co-founder of Sparrow Remote Solutions, is responsible for overseeing Sparrow’s operations and growth, finding novel ways to add value for our clients.<br><br>Modern problems require complex solutions, and Bojan’s background in operations & supply chain management makes him well positioned to address complicated operational issues as they arise. Prior to co-founding Sparrow, Bojan worked at <i>Fulfil</i>, one of Canada’s leading ERP providers based in Toronto.<br><br>Bojan holds a Bachelor's degree from the Sauder School of Business at the University of British Columbia – graduating with honors and a dual specialization in Global Supply Chain Management & Operations Logistics.<br><br>Bojan has notable experience in optimization and increasing operational efficiency for industrial workflows."
    },
    {
    nameAndSurname:'Natasha Vlajnic',
    role:"CFO",
    picture:"photos/team/NatasaBW.jpg",
    shortBio:"Natasha co-founded Sparrow, and is charged with overseeing its expansion. Driven by a view to the future, Natasha takes pride...",
    bio:"<p>Natasha co-founded Sparrow, and is charged with overseeing its expansion. Driven by a view to the future, Natasha takes pride in bringing innovative solutions to age-old problems.<br><br> Natasha holds a Juris Doctor Degree from the Peter A. Allard School of Law at the University of British Columbia, where she received recognition for achieving top standing in the Business Law specialization. Natasha also holds a Bachelor of Arts in Economics and International Relations from the University of British Columbia.<br><br> Natasha has significant experience in capital markets and transaction-based legal practice, including roles advising public and private companies on corporate and securities matters, governance and sustainability.</p>"
    }
]
