export const teamLeads=[
    {
    nameAndSurname:'Nikola Sovilj',
    role:"Software Engineer",
    picture:"photos/team/NikolaBW.jpg",
    shortBio:"Nikola plays an important role in developing Sparrow’s software infrastructure, creating modern and reusable solutions, offering...",
    bio:"<p>Nikola plays an important role in developing Sparrow’s software infrastructure, creating modern and reusable solutions, offering clients the best possible user experience.<br><br>Armed with knowledge from the Faculty of Technical Sciences at the University of Novi Sad, Nikola has successfully implemented over 20 software applications.<br><br>Nikola is experienced in building a wide variety of full-stack web and desktop applications, supported by technologies such as Java and React. Those projects include working with database, front-end, and back-end sides of the application. Additionally, Nikola has extensive experience in developing AI applications using Python.</p>"
    },
    {
    nameAndSurname:'Muaad Badurdeen',
    role:"Software Engineer",
    picture:"photos/team/MuaadBW.jpg",
    shortBio:"As a team lead at Sparrow, Muaad maintains alignment between the company's software development services and the ever-changing needs...",
    bio:"As a team lead at Sparrow, Muaad maintains alignment between the company's software development services and the ever-changing needs of its clients. With a mindset of excellence, Muaad procures continued education opportunities for the team at Sparrow, building an industry-leading service provider.<br><br>Muaad holds a Bachelor's degree from the Sauder School of Business at the University of British Columbia, graduating with a dual degree in Computer Science and Business Technology Management. He received recognition for his top standing in the Business Technology Management program.<br><br>Muaad has significant experience in technology consulting and project management, where he provides technical guidance to program managers, considering unique user needs and product requirements."
    },
    {
    nameAndSurname:'Mihal Sabados',
    role:"Software Engineer",
    picture:"photos/team/MihalBW.jpg",
    shortBio:"With over 7 years of experience programming and knowledge from Software Engineering at Faculty of Technical Sciences....",
    bio:"<p>Mihal plays an important role in developing Sparrow’s software infrastructure, creating modern and reusable solutions, offering clients the best possible user experience.<br><br>With over 7 years of experience programming and knowledge from Software Engineering at Faculty of Technical Sciences at the University of Novi Sad, Mihal has worked on over 20 software applications both solo and in teams.<br><br>Mihal's speciality is building web and desktop applications with technologies such as Java, React and Vue. He has experience working both on the front-end and back-end sides of the application. Additionally, Mihal has extensive experience in developing AI applications, Compilers and Scripts.</p>"
    },
    {
    nameAndSurname:'Leon Aksin',
    role:"Senior 3D Animation Specialist",
    picture:"photos/team/LeonBW.jpg",
    shortBio:"Leon is a digital artist focusing on 3D animation and game cinematics. Leon drives Sparrow’s creative heartbeat with over 8 years of experience. He holds...",
    bio:"Leon is a digital artist focusing on 3D animation and game cinematics. Leon drives Sparrow’s creative heartbeat with over 8 years of experience. He holds a Master of Arts degree from the University of Zagreb, Academy of Fine Arts and a Bachelor of Arts degree from the University of  Novi Sad, Academy of Arts, specializing in animation filmmaking and VFX.<br><br>Leon has extensive experience in all fields of the 3D production pipeline. His accomplishments in the computer graphics industry include several viral online shorts, official music videos for <i>Balkaton</i>, and an award-winning short film at the International Swindon Festival. Leon’s most recent works consist of high-end visuals for Bloomingdale's virtual production and a live screening of a promo video at New York’s Times Square.<br><br>His work in the industry has made him a part of the preproduction and postvis team for published projects like Call of Duty: Vanguard & Warzone, Company of Heroes 3, and Netflix's The Witcher."
    },
    {
    nameAndSurname:'Nikola Popadic',
    role:"Medior Full Stack Developer",
    picture:"photos/team/NikolaPopadicBW.jpg",
    shortBio:"A self-declared ‘swiss army knife’, Nikola is an integral part of our full-stack development division. With almost half a decade...",
    bio:"A self-declared ‘swiss army knife’, Nikola is an integral part of our full-stack development division. With almost half a decade of experience, Nikola’s strengths lie in his aptitude for using Java, JavaScript, React, and knowledge of full stack development workflows.<br><br>Besides his experience as a web developer, Nikola has begun branching out into DevOps process flows, with a particular focus on agility and automation. His skill set is continuously improving, and Nikola has taken part in numerous DevOps projects which bridge the gap between development and operations."
    },
    {
    nameAndSurname:'Aleksa Protic',
    role:"Medior Full Stack Developer",
    picture:"photos/team/AleksaBW.jpg",
    shortBio:"Aleksa has worked on a wide variety of applications, both in teams and in an independent capacity....",
    bio:"Aleksa has worked on a wide variety of applications, both in teams and in an independent capacity.<br><br>Aleksa has extensive experience in developing full-stack web applications using technologies such as Java, Python, React and Angular for both the back-end and front-end functions. Additionally, he has worked on building Android applications using Kotlin and Nodejs respectively.<br><br>"
    }
]