import { Grid} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import "./solutions.css"
import CodeIcon from '@mui/icons-material/Code';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import BrushIcon from '@mui/icons-material/Brush';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SolutionsDialog from './SolutionsDialog';

const SolutionsPage = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const myRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(()=>{
    console.log(windowSize);
  },[windowSize])

  const handleScroll = () =>
  {
    myRef.current.scrollIntoView()
  }

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  function viewDialog(tit){
    setTitle(tit);
    setOpenDialog(true);
  }

  const spacing = windowSize.innerWidth>770?6:3;


  return (
    <div>
        <SolutionsDialog openDialog={openDialog} setOpenDialog={setOpenDialog} title={title}/>
        <div className='solutionsHeaderBackground'></div>
        <div className='solutionsHeaderContent'>
          <h1>Sparrow Solutions</h1>
          <h3>
          Sparrow provides comprehensive Information Technology services and maintains tailored remote work solutions.
          </h3>
          <button className='solutionHButton' onClick={handleScroll}>LEARN MORE</button>
        </div>
        <div className='whiteSolutionDiv' ref={myRef} /*style={{marginTop:'-20px', position:'relative'}}*/>
          <div className='solutionsContent'>
            <Grid container spacing={spacing} style={{justifyContent:'center'}}>
              <Grid item xs="auto">
                <div className='solutionBox'>
                  <p className='FullStack'><CodeIcon style={{fontSize:'50'}}/></p>
                  <h2>Full Stack Development</h2>
                  <div className='buttonDiv'>
                    <button className='boxButton' onClick={()=>viewDialog("Full Stack Development")}>More info</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='solutionBox'>
                  <p><ThreeDRotationIcon style={{fontSize:'50'}}/></p>
                  <h2>3D Animation</h2>
                  <div className='buttonDiv'>
                    <button className='boxButton' onClick={()=>viewDialog("3D Animation")}>More info</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='solutionBox'>
                  <p className='GraphDesign'><BrushIcon style={{fontSize:'50'}}/></p>
                  <h2>Graphic Design</h2>
                  <div className='buttonDiv'>
                    <button className='boxButton' onClick={()=>viewDialog("Graphic Design")}>More info</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='solutionBox'>
                  <p><AllInclusiveIcon style={{fontSize:'50'}}/></p>
                  <h2>DevOps</h2>
                  <div className='buttonDiv'>
                    <button className='boxButton' onClick={()=>viewDialog("DevOps")}>More info</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='solutionBox'>
                  <p><PhoneIphoneIcon style={{fontSize:'50'}}/></p>
                  <h2>UI Design</h2>
                  <div className='buttonDiv'>
                    <button className='boxButton' onClick={()=>viewDialog("UI Design")}>More info</button>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='solutionBox'>
                  <p><EmojiEmotionsIcon style={{fontSize:'50'}}/></p>
                  <h2>UX Design</h2>
                  <div className='buttonDiv'>
                    <button className='boxButton' onClick={()=>viewDialog("UX Design")}>More info</button>
                  </div>
                </div>
              </Grid>
              
            </Grid>
          </div>
        </div>
    </div>
  )
}

export default SolutionsPage