import React  from 'react';
import Map from 'devextreme-react/map';
import './map.css'

const MapComp = ({location}) => {

  const markerUrl = 'https://www.sparrowremotesolutions.com/marker.png';
  const address = location.street + ', ' + location.city + ', ' + location.country;
  const mapAddress = [{
    location: address
  }];

  const keys = {
    bing:'AlstwBdODC4cBCSmxpr5dbIjtUjBm82ZQB06GswpmWD1AZDYBVgNzn4j1lsBlb4Y'
  }
  
  return (
        <Map className='map' style={{overflow:'hidden'}}
            defaultCenter={address}
            defaultZoom={11}
            apiKey={keys}
            controls={false}
            markerIconSrc={markerUrl}
            markers={mapAddress}
            provider="bing">
        </Map>

  )
}

export default MapComp