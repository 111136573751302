import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Link, useLocation, useNavigate} from "react-router-dom"
import logo from './photos/logo2.jpg'
import './dynamicNavbar.css'
import { useState, useEffect } from 'react';

const pages = ['About', 'Our Team', 'Solutions', 'Careers', 'Contact Us'];

const DynamicNavbar = ({onSiteRef, remoteRef}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [clickedMenuItem, setClickedMenuItem] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  useEffect(() => {
    scrollFunction();
  }, [windowSize.innerWidth])
  

  React.useEffect(()=>{
    console.log(location.pathname);

    switch(location.pathname){
      case "/about":
        setClickedMenuItem("About");
        break;
      case "/our%20team":
        setClickedMenuItem("Our Team");
        break;
      case "/solutions":
        setClickedMenuItem("Solutions");
        break;
      case "/careers":
        setClickedMenuItem("Careers");
        break;
      case "/careers/true":
        setClickedMenuItem("Careers");
        break;
      case "/careers/false":
        setClickedMenuItem("Careers");
        break;
      case "/contact":
        setClickedMenuItem("Contact Us");
        break;
      default:
        setClickedMenuItem("");

    }

  }, [location])

  const handleMiniLogoClick = () =>
  {
    navigate('/')
  }

  const handleOpenNavMenu = (event) => {
    console.log('otvara')
    setAnchorElNav(!anchorElNav);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    console.log("ZATVARA")
  };

  
  window.onscroll = function() {scrollFunction()};

  const handleLinkClick = (e) =>
  {
    if(e.target.name === clickedMenuItem)
    {
      window.scrollTo(0, 0)
    }
  }

  function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) { 

      //mini

      // if (windowSize.innerWidth < 900)
      // {
      //   if(windowSize.innerWidth <= 330)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "50px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "15px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px";
      //     document.getElementById("navbar").style.height = "80px"; 
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "5%";
      //     document.getElementById('icon-button').style.marginLeft = "10px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //     document.getElementById("navbar").style.minWidth = "280px"; 
      //   }

      //   else if(windowSize.innerWidth > 330 && windowSize.innerWidth <= 364)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "50px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "15px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px";
      //     document.getElementById("navbar").style.height = "80px"; 
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "10%";
      //     document.getElementById('icon-button').style.marginLeft = "10px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //     document.getElementById("navbar").style.minWidth = "280px"; 
      //   }

      //   else if (windowSize.innerWidth > 364 && windowSize.innerWidth <= 529)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "45px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "4px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "-6px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "80px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "5%";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }

      //   else if (windowSize.innerWidth > 529 && windowSize.innerWidth <= 639)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "45px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "-6px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "80px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "40px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "5%";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }

      //   else if (windowSize.innerWidth > 639 && windowSize.innerWidth <= 721)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "45px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "2px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "80px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "5%";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }

      //   else
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "40px";
      //     document.getElementById('sparrow-logo-mini').style.width = "60px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "10px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "6px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "150px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }
      // }

      if (windowSize.innerWidth > 1500 && windowSize.innerWidth <= 1750)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "-100px";
        document.getElementById("navbar").style.height = "55px";
        document.getElementById("logo-container").style.marginTop = '-29px';
        document.getElementById("navbar").style.padding = "5px 10px 5px";
        document.getElementById('sparrow-logo').style.height = "40px";
        document.getElementById('sparrow-logo').style.width = "60px";
        document.getElementById('sparrow-logo').style.marginTop = "26px";
        document.getElementById('sparrow-sign').style.fontSize = "20px";
        document.getElementById('sparrow-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "0px";
        document.getElementById('sparrow-sign').style.marginLeft = "0px";
        document.getElementById("navbar").style.paddingRight = "300px";
      }

      else if (windowSize.innerWidth > 1350 && windowSize.innerWidth <= 1500)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "-50px";
        document.getElementById("navbar").style.height = "55px";
        document.getElementById("logo-container").style.marginTop = '-29px';
        document.getElementById("navbar").style.padding = "5px 10px 5px";
        document.getElementById('sparrow-logo').style.height = "40px";
        document.getElementById('sparrow-logo').style.width = "60px";
        document.getElementById('sparrow-logo').style.marginTop = "26px";
        document.getElementById('sparrow-sign').style.fontSize = "20px";
        document.getElementById('sparrow-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "0px";
        document.getElementById('sparrow-sign').style.marginLeft = "0px";
        document.getElementById("navbar").style.paddingRight = "300px";
      }

      else if (windowSize.innerWidth > 1200 && windowSize.innerWidth <= 1350)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "0px";
        document.getElementById("navbar").style.height = "55px";
        document.getElementById("logo-container").style.marginTop = '-29px';
        document.getElementById("navbar").style.padding = "5px 10px 5px";
        document.getElementById('sparrow-logo').style.height = "40px";
        document.getElementById('sparrow-logo').style.width = "60px";
        document.getElementById('sparrow-logo').style.marginTop = "26px";
        document.getElementById('sparrow-sign').style.fontSize = "20px";
        document.getElementById('sparrow-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "0px";
        document.getElementById('sparrow-sign').style.marginLeft = "0px";
        document.getElementById("navbar").style.paddingRight = "270px";
      }

      else if (windowSize.innerWidth > 1050 && windowSize.innerWidth <= 1200)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "40px";
        document.getElementById("navbar").style.height = "55px";
        document.getElementById("logo-container").style.marginTop = '-29px';
        document.getElementById("navbar").style.padding = "5px 10px 5px";
        document.getElementById('sparrow-logo').style.height = "40px";
        document.getElementById('sparrow-logo').style.width = "60px";
        document.getElementById('sparrow-logo').style.marginTop = "26px";
        document.getElementById('sparrow-sign').style.fontSize = "13px";
        document.getElementById('sparrow-sign').style.marginTop = "3px";
        document.getElementById('remote-solutions-sign').style.fontSize = "9px";
        document.getElementById('remote-solutions-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "0px";
        document.getElementById('sparrow-sign').style.marginLeft = "0px";
        document.getElementById("navbar").style.paddingRight = "200px";
        document.getElementById('remote-solutions-sign-2').style.fontSize = "9px";
        document.getElementById('remote-solutions-sign-2').style.marginLeft = "0px";
      }

      else if (windowSize.innerWidth >= 900 && windowSize.innerWidth <= 1050)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "40px";
        document.getElementById("navbar").style.height = "55px";
        document.getElementById("logo-container").style.marginTop = '-29px';
        document.getElementById("navbar").style.padding = "5px 10px 5px";
        document.getElementById('sparrow-logo').style.height = "40px";
        document.getElementById('sparrow-logo').style.width = "60px";
        document.getElementById('sparrow-logo').style.marginTop = "26px";
        document.getElementById('sparrow-sign').style.fontSize = "13px";
        document.getElementById('sparrow-sign').style.marginTop = "3px";
        document.getElementById('remote-solutions-sign').style.fontSize = "9px";
        document.getElementById('remote-solutions-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "0px";
        document.getElementById('sparrow-sign').style.marginLeft = "0px";
        document.getElementById("navbar").style.paddingRight = "160px";
        document.getElementById('remote-solutions-sign-2').style.fontSize = "9px";
        document.getElementById('remote-solutions-sign-2').style.marginLeft = "0px";
      }

      else if (windowSize.innerWidth > 1750){
        document.getElementById("navbar").style.height = "55px";
        document.getElementById("logo-container").style.marginTop = '-29px';
        document.getElementById("navbar").style.padding = "5px 10px 5px";
        document.getElementById('sparrow-logo').style.height = "40px";
        document.getElementById('sparrow-logo').style.width = "60px";
        document.getElementById('sparrow-logo').style.marginTop = "26px";
        document.getElementById('sparrow-sign').style.fontSize = "20px";
        document.getElementById('sparrow-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign').style.marginTop = "0px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "0px";
        document.getElementById('sparrow-sign').style.marginLeft = "0px";
        document.getElementById("navbar").style.paddingRight = "400px";
        document.getElementById('sparrow-logo').style.marginLeft = "-60px";
      }
      else{
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
      }

    } else {

      //mini

      // if (windowSize.innerWidth < 900)
      // {
      //   if(windowSize.innerWidth < 330)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "50px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "15px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px";
      //     document.getElementById("navbar").style.height = "80px"; 
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "5%";
      //     document.getElementById('icon-button').style.marginLeft = "10px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //     document.getElementById("navbar").style.minWidth = "280px"; 
      //   }

      //   else if(windowSize.innerWidth > 330 && windowSize.innerWidth <= 364)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "50px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "15px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px";
      //     document.getElementById("navbar").style.height = "80px"; 
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "20%";
      //     document.getElementById('icon-button').style.marginLeft = "10px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //     document.getElementById("navbar").style.minWidth = "280px"; 
      //   }

      //   else if (windowSize.innerWidth > 364 && windowSize.innerWidth <= 529)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "45px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "4px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "-6px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "80px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "30%";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }

      //   else if (windowSize.innerWidth > 529 && windowSize.innerWidth <= 639)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "45px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "-6px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "80px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "40px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "30%";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }

      //   else if (windowSize.innerWidth > 639 && windowSize.innerWidth <= 721)
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "45px";
      //     document.getElementById('sparrow-logo-mini').style.width = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "5px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "2px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "80px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "150px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }

      //   else
      //   {
      //     document.getElementById('sparrow-logo-mini').style.height = "40px";
      //     document.getElementById('sparrow-logo-mini').style.width = "60px";
      //     document.getElementById('sparrow-logo-mini').style.marginTop = "10px";
      //     document.getElementById('sparrow-sign-mini').style.fontSize = "20px";
      //     document.getElementById('sparrow-sign-mini').style.marginTop = "6px";
      //     document.getElementById('remote-solutions-sign-mini').style.fontSize = "12px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginTop = "0px";
      //     document.getElementById('remote-solutions-sign-mini').style.marginLeft = "0px";
      //     document.getElementById('sparrow-sign-mini').style.marginLeft = "0px"; 
      //     document.getElementById("navbar").style.height = "65px";
      //     document.getElementById('sparrow-logo-mini').style.marginLeft = "150px";
      //     document.getElementById('icon-button').style.marginTop = "5px";
      //   }
      // }

      if (windowSize.innerWidth > 1500 && windowSize.innerWidth <= 1750)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "-100px";
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
        document.getElementById('sparrow-logo').style.height = "65px";
        document.getElementById('sparrow-logo').style.width = "90px";
        document.getElementById('sparrow-logo').style.marginTop = "10px";
        document.getElementById('sparrow-sign').style.fontSize = "32px";
        document.getElementById('sparrow-sign').style.width = "10%";
        document.getElementById('sparrow-sign').style.marginTop = "-20px";
        document.getElementById('remote-solutions-sign').style.fontSize = "16px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "6px";
        document.getElementById('sparrow-sign').style.marginLeft = "6px";
        document.getElementById("navbar").style.paddingRight = "300px";
      }

      else if (windowSize.innerWidth > 1350 && windowSize.innerWidth <= 1500)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "-50px";
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
        document.getElementById('sparrow-logo').style.height = "65px";
        document.getElementById('sparrow-logo').style.width = "90px";
        document.getElementById('sparrow-logo').style.marginTop = "10px";
        document.getElementById('sparrow-sign').style.fontSize = "32px";
        document.getElementById('sparrow-sign').style.width = "10%";
        document.getElementById('sparrow-sign').style.marginTop = "-20px";
        document.getElementById('remote-solutions-sign').style.fontSize = "16px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "6px";
        document.getElementById('sparrow-sign').style.marginLeft = "6px";
        document.getElementById("navbar").style.paddingRight = "300px";
      }

      else if (windowSize.innerWidth > 1200 && windowSize.innerWidth <= 1350)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "0px";
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
        document.getElementById('sparrow-logo').style.height = "55px";
        document.getElementById('sparrow-logo').style.width = "80px";
        document.getElementById('sparrow-logo').style.marginTop = "18px";
        document.getElementById('sparrow-sign').style.fontSize = "26px";
        document.getElementById('sparrow-sign').style.width = "10%";
        document.getElementById('sparrow-sign').style.marginTop = "-8px";
        document.getElementById('remote-solutions-sign').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "6px";
        document.getElementById('sparrow-sign').style.marginLeft = "6px";
        document.getElementById("navbar").style.paddingRight = "270px";
      }

      else if (windowSize.innerWidth > 1050 && windowSize.innerWidth <= 1200)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "0px";
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
        document.getElementById('sparrow-logo').style.height = "55px";
        document.getElementById('sparrow-logo').style.width = "80px";
        document.getElementById('sparrow-logo').style.marginTop = "18px";
        document.getElementById('sparrow-sign').style.fontSize = "26px";
        document.getElementById('sparrow-sign').style.width = "10%";
        document.getElementById('sparrow-sign').style.marginTop = "-20px";
        document.getElementById('remote-solutions-sign').style.fontSize = "14px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "6px";
        document.getElementById('sparrow-sign').style.marginLeft = "6px";
        document.getElementById("navbar").style.paddingRight = "200px";
        document.getElementById('remote-solutions-sign-2').style.fontSize = "14px";
        document.getElementById('remote-solutions-sign-2').style.marginLeft = "5px";
      }

      else if (windowSize.innerWidth >= 900 && windowSize.innerWidth <= 1050)
      {
        document.getElementById('sparrow-logo').style.marginLeft = "40px";
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
        document.getElementById('sparrow-logo').style.height = "55px";
        document.getElementById('sparrow-logo').style.width = "80px";
        document.getElementById('sparrow-logo').style.marginTop = "18px";
        document.getElementById('sparrow-sign').style.fontSize = "14px";
        document.getElementById('sparrow-sign').style.width = "10%";
        document.getElementById('sparrow-sign').style.marginTop = "-4px";
        document.getElementById('remote-solutions-sign').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "6px";
        document.getElementById('sparrow-sign').style.marginLeft = "6px";
        document.getElementById("navbar").style.paddingRight = "160px";
        document.getElementById('remote-solutions-sign-2').style.fontSize = "12px";
        document.getElementById('remote-solutions-sign-2').style.marginLeft = "5px";
      }

      else if (windowSize.innerWidth > 1750)
      {
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
        document.getElementById('sparrow-logo').style.height = "65px";
        document.getElementById('sparrow-logo').style.width = "90px";
        document.getElementById('sparrow-logo').style.marginTop = "10px";
        document.getElementById('sparrow-sign').style.fontSize = "32px";
        document.getElementById('sparrow-sign').style.width = "10%";
        document.getElementById('sparrow-sign').style.marginTop = "-20px";
        document.getElementById('remote-solutions-sign').style.fontSize = "16px";
        document.getElementById('remote-solutions-sign').style.marginLeft = "6px";
        document.getElementById('sparrow-sign').style.marginLeft = "6px";
        document.getElementById("navbar").style.paddingRight = "400px";
        document.getElementById('sparrow-logo').style.marginLeft = "-70px";
      }
      else{
        document.getElementById("navbar").style.padding = "55px 10px";
        document.getElementById("logo-container").style.marginTop = '-50px';
        document.getElementById("logo").style.fontSize = "35px";
      }
    }
  }

  const mouseOverMenuItems = (e)=>{
    if(e.target.name === 'Careers'){
    }
    else if(e.target.name === 'Solutions'){
    }
    else{
      handleCloseNavMenu(e); 
    }

  }

  const goToHomepage = ()=>{
    setClickedMenuItem("");
    navigate("/");
  }

  return (
    <AppBar id='navbar' style={{backgroundColor:'rgba(13,103,181,255)', position:'fixed', padding: "55px 10px", borderBottomLeftRadius: '60px', borderBottomRightRadius: '60px', minWidth:'280px'}} >  
        <Box id='logo-container' style={{marginTop: '-50px'}} display='flex' justifyContent={{xs:'left', sm:'left', md:"center", lg:'center'}} >
                <Box id='logo' display= {{ xs: 'none', md: 'flex', width:'30%', cursor: 'pointer'}} onClick={goToHomepage}>
                        <img id='sparrow-logo' src={logo} style={{height:'50px', marginTop:'22px'}} />
                        <div id='div-sparrow-sign' style={{paddingLeft:'10px'}}>
                          <Typography
                              id='sparrow-sign'
                              variant="h6"
                              component="p"
                              href="/"
                              sx={{
                              mr: 2,
                              display: { xs: 'none', md: 'flex' },
                              fontFamily: 'monospace',
                              fontWeight: 600,
                              letterSpacing: '.2rem',
                              color: 'inherit',
                              textDecoration: 'none'
                              }}
                              style={{paddingBottom:'0px', paddingTop:'22px'}}
                          >
                              Sparrow
                          </Typography>
                          {windowSize.innerWidth >= 1200 && <Typography
                              id='remote-solutions-sign'
                              variant="h6"
                              component="p"
                              href="/"
                              sx={{
                              mr: 2,
                              display: { xs: 'none', md: 'flex' },
                              fontFamily: 'monospace',
                              fontSize:'12px',
                              fontWeight: 200,
                              letterSpacing: '.2rem',
                              justifyContent:'top',
                              color: 'inherit',
                              textDecoration: 'none',
                              }}
                              style={{paddingTop:'0px'}}
                            
                          >
                              Remote Solutions
                          </Typography>}


                          {(windowSize.innerWidth >= 900 && windowSize.innerWidth < 1200) && 
                            <div>
                                <Typography
                                  id='remote-solutions-sign'
                                  variant="h6"
                                  component="p"
                                  href="/"
                                  sx={{
                                  mr: 2,
                                  display: { xs: 'none', md: 'flex' },
                                  fontFamily: 'monospace',
                                  fontSize:'12px',
                                  fontWeight: 200,
                                  letterSpacing: '.2rem',
                                  justifyContent:'top',
                                  color: 'inherit',
                                  textDecoration: 'none',
                                  }}
                                  style={{paddingTop:'0px'}}
                                  
                              >
                                  Remote
                              </Typography>
                              <Typography
                                  id='remote-solutions-sign-2'
                                  variant="h6"
                                  component="p"
                                  href="/"
                                  sx={{
                                  mr: 2,
                                  display: { xs: 'none', md: 'flex' },
                                  fontFamily: 'monospace',
                                  fontSize:'12px',
                                  fontWeight: 200,
                                  letterSpacing: '.2rem',
                                  justifyContent:'top',
                                  color: 'inherit',
                                  textDecoration: 'none',
                                  }}
                                  style={{paddingTop:'0px'}}
                                  
                              >
                                  Solutions
                              </Typography>
                            </div>
                            }
                        </div>
                </Box>
                <Box display= {{ xs: 'none', md: 'flex' }}>
                    <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                        <Link onClick={handleLinkClick} to={(page === 'Careers') ? ('careers/true') : ((page === ('Contact Us') ? ('contact') : page.toLowerCase()))} style={{textDecoration: 'none'}}>
                          <Button
                            onMouseOv
                            key={page}
                            name={page}
                            onMouseOver={mouseOverMenuItems}
                            sx={{ my: 2, color: 'white', textTransform: 'Capitalize', fontFamily: "Nunito, sans-serif", fontSize:'18px', whiteSpace:'nowrap',
                            '&:hover':{color:'darkgray'}, 
                            "&::before":clickedMenuItem==page ?{
                              content: `""`,
                              height: '5px !important',
                              background: '#99d9ea',
                              position: 'absolute',
                              marginTop: '25px !important',
                              width: '85%',
                              zIndex: '10'
                            }:{}
                            }}
                        >
                            {page.toLowerCase()}
                        </Button>
                        </Link>
                        ))}
                    </Box>
                </Box>
                <Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, ml:'15%', my:'15px' }}>
                  
                        <IconButton
                        id="icon-button"
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon/>
                        </IconButton>
                          {
                            anchorElNav &&
                            <Box
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                color:'white',
                                backgroundColor:'rgba(13,103,181,255)',
                                position:'absolute',
                                mt:'50px'
                            }}
                            >
                            {pages.map((page) => (
                                  <MenuItem key={page} name={page} 
                                  onClick={(e)=>{
                                    e.stopPropagation();
                                    navigate((page === 'Careers') ? ('careers/true') : ((page === ('Contact Us') ? ('contact') : page.toLowerCase())));
                                    console.log(e.target.textContent);
                                    console.log("EVO MEEE");
                                    handleCloseNavMenu();
                                }}
                                style={{
                                  color: 'white',
                                  padding:'12px',
                                  '&:hover':{backgroundColor:'aliceblue', color:'rgba(13,103,181,255)'}
                                }}
                                >
                                <Typography textAlign="center" sx={{fontFamily: "Nunito, sans-serif", fontSize:'18px', py:'5px'}}>{page}</Typography>
                                
                                </MenuItem>
                            ))}
                            </Box>
                          }
                    </Box>
                </Box> 
            <Box sx={{ mx:'auto', my:'20px'}} display= {{ xs: 'flex', md: 'none' }} onClick={handleMiniLogoClick}>
                        <img id="sparrow-logo-mini" src={logo} style={{height:'50px', marginTop:'0px', flexDirection:"row", marginLeft:'10px'}} />
                        {windowSize.innerWidth < 900 && <div>
                          <Typography
                              id="sparrow-sign-mini"
                              variant="h6"
                              component="p"
                              href="/"
                              sx={{
                              paddingLeft:"10px",
                              mr: 2,
                              display: { xs: 'flex', md: 'none' },
                              fontFamily: 'monospace',
                              fontWeight: 600,
                              letterSpacing: '.2rem',
                              color: 'white',
                              textDecoration: 'none'
                              }}
                              style={{paddingBottom:'0px', paddingTop:'0px'}}
                          >
                              Sparrow
                          </Typography>
                          <Typography
                              id="remote-solutions-sign-mini"
                              variant="h6"
                              component="p"
                              href="/"
                              sx={{
                              paddingLeft:"10px",
                              mr: 2,
                              display: { xs: 'flex', md: 'none' },
                              fontFamily: 'monospace',
                              fontSize:'12px',
                              fontWeight: 200,
                              letterSpacing: '.2rem',
                              justifyContent:'top',
                              color: 'inherit',
                              textDecoration: 'none',
                              }}
                              style={{paddingTop:'0px', flexDirection:'row'}}
                          >
                              Remote Solutions
                          </Typography>
                        </div>}
            </Box>
        </Box>
    </AppBar>
  );
};
export default DynamicNavbar;
