import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {useNavigate} from "react-router-dom"
import './about.css'
import { useRef } from 'react';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AddIcon from '@mui/icons-material/Add';
import ShieldIcon from '@mui/icons-material/Shield';

const AboutPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [boxHeight, setBoxHeight] = useState("250px");
  const navigate = useNavigate();

  const myRef = useRef(null);

  const handleScroll = () =>
  {
    myRef.current.scrollIntoView()
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  useEffect(()=>{
    console.log(windowSize);
    if(windowSize.innerWidth<1658 && windowSize.innerWidth>962)
      setBoxHeight("300px");
    else if(windowSize.innerWidth < 963 && windowSize.innerWidth > 900)
      setBoxHeight("350px");
    else if(windowSize.innerWidth < 900)
      setBoxHeight("auto");
    else
      setBoxHeight("250px");
  },[windowSize])

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
    <div>
        <div className='aboutHeaderBackground'></div>
        <div className='aboutHeaderContent'>
          <h1>About Sparrow</h1>
          <h3>
            The complexities of remote work are muddy, Sparrow’s purpose is clear – simplifying and implementing tailored remote solutions
          </h3>
          <button className='aboutHButton' onClick={handleScroll}>LEARN MORE</button>
        </div>

        <div className='whiteDiv' ref={myRef} style={{marginTop:'-20px', position:'relative', scrollMarginTop: '50px'}}>
          <div className='textContainerDiv'>
            <Typography
                variant='h2'
                component="p"
                style={{textAlign:'left', display:'block', marginBottom:'30px'}}
                sx={{
                  fontFamily: 'inherit',
                  fontSize:'32px',
                  fontWeight: 900
                }}
                >
                The Global Community Working for You
              </Typography>
            <p>At Sparrow, we see remote work as an opportunity to tap into the skill set of the global community. Leveraging Sparrow’s diverse knowledge and experience, remote digital services are provided by some of the very best talent in each respective field</p>
          </div>
          <div className='boxContainerDiv'>
            <Grid container spacing={3} style={{justifyContent:'center', marginTop:'30px'}}>
              <Grid item xs="auto">
                <div className='boxDiv'>
                  <div className='boxHeader'>
                    <h2>Connect</h2>
                    <p>
                      <ConnectWithoutContactIcon style={{fontSize:"60px"}}/>
                    </p>
                  </div>
                  <div className="boxDetails">
                    <p>
                      Sparrow helps facilitate employment opportunities which would otherwise go unnoticed, creating value where it would have otherwise gone unrealized. By offering top talent from around the world, we are able to deliver custom solutions to our clients – striking the balance between quality and affordability. Our team of highly trained technical experts and consultants fits effortlessly into the workings of your business, for a smooth transition to more functional solutions. 
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='boxDiv'>
                  <div className='boxHeader'>
                    <h2>Grow</h2>
                    <p>
                      <TrendingUpIcon style={{fontSize:"60px"}}/>
                    </p>
                  </div>
                  <div className="boxDetails">
                    <p>
                    Our integrated digital services allow business owners to benefit from peace of mind, knowing that a team of skilled professionals and an arsenal of international resources stands behind them. Allow Sparrow to take care of your day to day needs, and free up time to focus on growing other core aspects of your business. The value of Sparrow’s services is the value of your time back. 
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='boxDiv'>
                  <div className='boxHeader'>
                    <h2>Innovate</h2>
                    <p>
                      <AddIcon style={{fontSize:"60px"}}/>
                    </p>
                  </div>
                  <div className="boxDetails">
                    <p>
                    Each business relationship is unique, and each Sparrow service is individually tailored to your corporation’s needs. At Sparrow, providing reliable service is not enough. In addition to the tried and true, we focus on our teams’ continued education and development, to provide innovative and industry leading service that grows with you.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs="auto">
                <div className='boxDiv'>
                  <div className='boxHeader'>
                    <h2>Protect</h2>
                    <p>
                      <ShieldIcon style={{fontSize:"60px"}}/>
                    </p>
                  </div>
                  <div className="boxDetails">
                    <p>
                    Our client’s financial and operational well-being is at the core of Sparrow’s business model. Our digital service solutions are executed by an extensive network of industry-leading professionals. Sparrow's philosophy is simple – meticulous due diligence on our end delivers quality talent and exceptional results to you.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='aboutFooter'>
          <div className='aboutFooterBg'></div>
          <div className='aboutFooterContent'>
            <h1>We think outside of the box so you don’t have to.</h1>
            <h3>
            Find out how Sparrow adds value to your organizations.
            </h3>
            <button className='aboutFButton' onClick={()=>navigate("/solutions")}>SOLUTIONS</button>
          </div>
        </div>
       
    </div>
  )
}

export default AboutPage