import React, { useEffect, useState } from 'react'
import VideoHeader from '../VideoHeader'
import remote from '../photos/remote.jpg'
import teamwork from '../photos/teamwork.jpg'
import bussinessOwner from '../photos/BO.jpg'
import employee from '../photos/Emp.jpg'
import administration from '../photos/Adm.jpg'
import leaf from '../photos/Leaf.png'
import laptop from '../photos/laptop.png'
import people from '../photos/people.png'
import chart from '../photos/chart.png'
import './homepage.css'
import { Grid, Typography } from '@mui/material'
import { useRef } from 'react'

const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const myRef = useRef(null);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }


  return (
    <div>
        <VideoHeader title='Global Talent - Local Solutions' buttonText1={"LEARN MORE"} buttonText2={"CONTACT US"} myRef={myRef}></VideoHeader>
        <div className='blueDiv' ref={myRef}>
          <div style={{margin:'0px auto', width:windowSize.innerWidth>1200?'90%':'95%', paddingBottom:'50px'}}>
            <Grid container spacing={5} style={{justifyContent:'center'}}>
              <Grid item xs={12} md={6}>
                <img src={teamwork} alt="teamwork" style={{ width:'100%', marginTop:'50px'}}></img>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                variant='h2'
                component="p"
                style={{textAlign:'left', display:'block', marginBottom:'30px'}}
                sx={{
                  fontFamily: 'inherit',
                  fontSize:'32px',
                  fontWeight: 900
                }}
                >
                A New Standard of Work
                </Typography>
                <p style={{textAlign:'justify'}}>With the onset of the pandemic, the global labor market saw an unprecedented rise in remote work and flexible employment options. As the pandemic subsides and new challenges emerge, we are committed to continuing to bring global talent closer to home – helping our clients create value and sustain growth.</p>
                <p style={{fontWeight:'900', paddingTop:'15px', paddingBottom:'15px'}}>Striking the Balance Between Quality, Feasibility, and Affordability</p>
                <p style={{textAlign:'justify'}}>Finding technology solutions to suit your business shouldn’t be frustrating. At Sparrow, we believe that the ability to source talent from around the world will continue to drive innovation, particularly in the Information Technology sector. Sparrow is committed to being your one-stop-shop for integrated digital service solutions. Our highly qualified team brings a wealth of knowledge, expertise and innovation to provide efficient solutions to your business’ digital needs.</p>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{justifyContent:'center', marginTop:'30px'}}>
              <Grid item xs={12} md={6}>
              <Typography
                variant='h2'
                component="p"
                style={{textAlign:'left', display:'block', marginBottom:'30px'}}
                sx={{
                  fontFamily: 'inherit',
                  fontSize:'32px',
                  fontWeight: 900
                }}
                >Remote Work Simplified
                </Typography>
                <p style={{fontWeight:'900', paddingTop:'15px', paddingBottom:'10px'}}>Our purpose is to simplify the bureaucracy surrounding remote service providers. </p>
                <p style={{textAlign:'justify'}}>Sparrow tailors its solutions to every individual client, allowing for maximum flexibility while retaining efficiency. No two companies are alike – and neither is our approach.</p>
                <p style={{textAlign:'justify'}}>By partnering with Sparrow, clients receive fully integrated employment solutions, from sourcing talent to handling all administrative & legal processes. In essence, no more cumbersome Visa-Sponsorships, streamlined global accounting and invoicing, and increased savings in both time & money. </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <img src={remote} style={{ width:'100%'}}></img>
              </Grid>
            </Grid>
            </div>
        </div>
        <div style={{margin:'100px auto', width:'90%'}}>
          <Grid container spacing={2} style={{justifyContent:'center', marginBottom:'80px'}}>
                    <Grid item xs={'auto'}>
                      <div className='solutionCard' style={{border:'3px solid black', /*backgroundColor:'rgb(13,108,181)', color:'white'*/}}>
                        <h3>Sparrows Offers a Solution for Everyone</h3>
                        <p>Sparrow Remote Solutions is your one-stop-shop for all remote employment related solutions. Join us, and become a part of bringing global talent closer to home – one megabyte at a time.</p>
                      </div>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <div className='solutionCard'>
                        <img src={bussinessOwner}></img>
                        <div className='cardName'>
                          <h4>Business Owners</h4>
                          <p>Hover for more ↪</p>
                        </div>
                        <div className='moreInfoDiv'>                        
                            <h4>Business Owners</h4>
                            <p>Access to global talent (Software Developers, Full Stack Developers, Graphic Designers, etc.), payment options, continuous 24/7 support and customizability. Easy access to global markets and employment solutions.</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <div className='solutionCard'>
                        <img src={employee}></img>
                        <div className='cardName'>
                          <h4>Employees & Contractors</h4>
                          <p>Hover for more ↪</p>
                        </div>
                        <div className='moreInfoDiv'>                        
                            <h4>Employees & Contractors</h4>
                            <p>Access to Employment around the world, work-from-anywhere solutions. Roles across the IT industry including: Software Developers, Full Stack Developers, Graphic Designers, 3D Animators, UI/UX Designers, etc.. </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <div className='solutionCard'>
                        <img src={administration}></img>
                        <div className='cardName'>
                          <h4>Administration & Bureaucracy</h4>
                          <p>Hover for more ↪</p>
                        </div>
                        <div className='moreInfoDiv'>                        
                            <h4>Administration & Bureaucracy</h4>
                            <p>Reliable and effective service arrangements, complete service provider assessments, regulatory compliance, and safety.</p>
                        </div>
                      </div>
                    </Grid>
          </Grid>
          <Typography
                  variant='h2'
                  component="p"
                  style={{textAlign:'center', display:'block', marginBottom:'30px'}}
                  sx={{
                    fontFamily: 'inherit',
                    fontSize:'32px',
                    fontWeight: 900
                  }}
                  >One-Stop Shop for Remote Employment Solutions
          </Typography>
          <Grid container spacing={5} style={{justifyContent:'center', marginTop:'10px'}}>
            <Grid item xs={'auto'}>
              <div style={{width:'250px'}}>
                <div style={{marginLeft:'10%'}}>
                  <img src={laptop} style={{height:'200px'}}></img>
                </div>
                <h4 style={{padding:'0px', fontWeight:'bold'}}>Tech enabled employment solutions servicing the global community</h4>
              </div>
            </Grid>  
            <Grid item xs={'auto'}>
              <div style={{width:'250px'}}>
                <div style={{marginLeft:'10%'}}>
                  <img src={people} style={{height:'200px'}}></img>
                </div>
                <h4 style={{padding:'0px', fontWeight:'bold'}}>A direct relationship with business owners, service providers, and state administration</h4>
              </div>
            </Grid>  
            <Grid item xs={'auto'}>
              <div style={{width:'250px'}}>
                <div style={{marginLeft:'10%'}}>
                  <img src={leaf} style={{height:'200px', /*filter:'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)'*/}}></img>
                </div>
                <h4 style={{padding:'0px', fontWeight:'bold'}}>A proud Canadian company with head offices in Vancouver - British Columbia</h4>
              </div>
            </Grid>  
            <Grid item xs={'auto'}>
              <div style={{width:'250px'}}>
                <div style={{marginLeft:'10%'}}>
                  <img src={chart} style={{height:'200px'}}></img>
                </div>
                <h4 style={{padding:'0px', fontWeight:'bold'}}>One of the fastest growing employment solutions companies in the country</h4>
              </div>
            </Grid>  
            
          </Grid>
          
        </div>
    </div>
  )
}

export default HomePage