import React from 'react';
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
import './footer.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { spacing } from '@mui/system';
import logo from '../photos/logo3.png'
import { Typography } from '@mui/material';

const Footer = () => {

  return (
    <CDBFooter className="shadow">
      <CDBBox id="footer-container" display="flex" flex="column" className="mx-auto py-5" style={{width:'65%'}}>
        <CDBBox display="flex" justifyContent="between" className="flex-wrap" style={{marginBottom:'-70px'}}>
          <CDBBox style={{marginTop: '8px'}}>
          <div style={{display:'flex', margin:'0px auto', marginBottom:'40px', marginTop:'-30px'}}>
              <img src={logo} style={{height:'50px', marginTop:'30px'}} />
              <div style={{paddingLeft:'10px', color: "rgba(13,103,181,255)", fontSize: '35px', fontWeight: '800'}}>
                            <Typography
                                variant="h6"
                                component="p"
                                href="/"
                                sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'flex' },
                                fontFamily: 'monospace',
                                fontSize: '18px',
                                fontWeight: 800,
                                letterSpacing: '.2rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                }}
                                style={{paddingBottom:'0px', paddingTop:'22px'}}
                            >
                                Sparrow
                            </Typography>
                            <Typography
                                variant="h6"
                                component="p"
                                href="/"
                                sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'flex' },
                                fontFamily: 'monospace',
                                fontSize:'12px',
                                fontWeight: 400,
                                letterSpacing: '.2rem',
                                justifyContent:'top',
                                color: 'inherit',
                                textDecoration: 'none',
                                }}
                                style={{paddingTop:'0px'}}
                            >
                                Remote
                            </Typography>
                            <Typography
                                variant="h6"
                                component="p"
                                href="/"
                                sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'flex' },
                                fontFamily: 'monospace',
                                fontSize:'12px',
                                fontWeight: 400,
                                letterSpacing: '.2rem',
                                justifyContent:'top',
                                color: 'inherit',
                                textDecoration: 'none',
                                }}
                                style={{paddingTop:'0px'}}
                            >
                                Solutions
                            </Typography>
                          </div>


            </div>
            <p className="my-3" style={{ width: '180px' }}>
            1010 Richards Street
            Vancouver, BC,
            <b> Canada</b>
            </p>
            <CDBBox display="flex" className="mt-4" style={{paddingBottom:'100px'}}>
              <a href="https://www.linkedin.com/home">
                <CDBBtn style={{backgroundColor: "rgba(13,103,181,255)", border: 'none'}}>
                  <LinkedInIcon></LinkedInIcon>
              </CDBBtn>
              </a>
              
              <a href="https://www.instagram.com/sparrow_rsolutions/" style={{marginLeft: '3%'}}>
                <CDBBtn style={{backgroundColor: "rgba(13,103,181,255)", border: 'none'}}>
                  <InstagramIcon></InstagramIcon>
              </CDBBtn>
              </a>

              <a href="https://twitter.com/?lang=sr" style={{marginLeft: '3%'}}>
                <CDBBtn style={{backgroundColor: "rgba(13,103,181,255)", border: 'none'}}>
                  <TwitterIcon></TwitterIcon>
              </CDBBtn>
              </a>
              
            </CDBBox>
          </CDBBox>
          <CDBBox>
            <p className="footer-sparrow" style={{ fontWeight: '800', color: 'rgba(13,103,181,255)', fontSize:'35px'}}>
            Links
            </p>
            <div style={{display: 'flex'}}>
            <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0', paddingBottom: '100px', whiteSpace: 'nowrap', marginRight:'35px', lineHeight: '40px' }}>
              <div style={{display: 'grid'}}>
                <a href="/about" style={{textDecoration: 'none'}}>About</a>
                <a href="/our%20team" style={{textDecoration: 'none'}}>Our Team</a>
                <a href="/solutions" style={{textDecoration: 'none'}}>Solutions</a>
                <a href="/careers/true" style={{textDecoration: 'none'}}>Careers</a>
              </div>
            </CDBBox>
            </div>
          </CDBBox>
          <CDBBox style={{marginTop: '4px'}} marginRight='0px'>
            <p className="h5 mb-4" style={{ fontWeight: '800', color: 'rgba(13,103,181,255)', fontSize:'35px', marginRight:'auto'}}>
              Help
            </p>
            <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0', paddingBottom: '100px', lineHeight: '40px'}}>
              <div style={{ display: 'grid' }}>
                <a href="/contact" style={{textDecoration: 'none'}} >Contact Us</a>
              </div>
            </CDBBox>
          </CDBBox>
        </CDBBox>
        <small className="text-center mt-5" style={{marginBottom:'-20px'}}>&copy; Sparrow Remote Solutions, 2022. All rights reserved.</small>
      </CDBBox>
    </CDBFooter>
  );
};

export default Footer;