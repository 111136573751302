import React from 'react'
import './contactPage.css'
import Header from '../../Header'
import FormInput from './formInput/FormInput'
import { useState, useEffect } from "react"
import { useNavigate } from "react-router";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import MapComp from './map/MapComponent'
import { width } from '@mui/system'
import { Snackbar, Alert } from '@mui/material'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import emailjs from 'emailjs-com'
import { countryPhoneCodes } from '../../data/countryPhoneCodes'

const ContactPage = ({ headerText, headerButtonText }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const address = {
    street: "1010 Richards Street",
    city: "Vancouver",
    country: "Canada"
  }

  const [values, setValues] = useState({
    name:"",
    lastName:"",
    email:"",
    tel:"",
    helpSelect:"I have a general inquiry",
    message:""
  })

  const [country, setCountry] = useState('');

  const inputs = [
    {
      id:1,
      name:"name",
      type:"text",
      placeholder:"First Name",
      errorMessage:"Invalid input!",
      label:"First Name",
      required: true, 
      pattern: `^[A-Z][a-z ,.'-]+$`
    },
    {
      id:2,
      name:"lastName",
      type:"text",
      placeholder:"Last Name",
      errorMessage:"Invalid input!",
      label:"Last Name",
      required: true,
      pattern: `^[A-Z][a-z ,.'-]+$`
    }
  ]

  const inputsSecondRow = [
    {
      id:3,
      name:"email",
      type:"email",
      placeholder:"Email",
      errorMessage:"It should be a valid email address!",
      label:"Email",
      required: true, 
    }
  ]

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const handleSubmit = (e) => {
    setCountry(country)
    onChangePhone(values.tel, country, e)
    e.preventDefault();
    openNotification()
    emailjs.send(`service_inyx9yg`, 'template_50au9lb', values, 'l8SVuixuQuYsO4nJs')

    setTimeout(function () {
      navigate('/')
    }, 2000);
  }

  const onChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
  }

  const findPhoneCode = (country) =>
  {
    let phoneCode = ''
    for (let i of countryPhoneCodes)
    {
      if(i.name === country.name)
      {
        phoneCode = i.dial_code;
      }
    }
    return phoneCode;
  }

  const onChangePhone = (value, country, event) => {
    
    var phoneCode = findPhoneCode(country);

    setValues({
      ...values,
      [event.target.type]: event.target.value,
    });

    if(event.target.value === undefined)
    {
      setValues({
        ...values, tel: phoneCode,
      });
      console.log(values)
    }
  };

  const navigate = useNavigate();

  function handleOnChange(value) {
    this.setState({
       phone: value
    });
  }

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
    ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const openNotification = () => {
    setOpen(true);
  };

  const closeNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
        <Header title='Contact Us' text={headerText} buttonText='none'></Header>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={open} autoHideDuration={2000} onClose={closeNotification}>
            <Alert onClose={closeNotification} severity="success" sx={{ width: '100%' }}>
              Email sent!
            </Alert>
          </Snackbar>
        </Stack>
        <Snackbar autoHideDuration={6000}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Email sent!
          </Alert>
        </Snackbar>
        <div className="registration">
          <div className="form">
            <h1>Get In Touch</h1>
            <form onSubmit={handleSubmit}>
              <div className="formElements">
                <div className="row">
                  {inputs.map((input) => (
                    <FormInput 
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange} 
                    />
                  ))}
                </div>
                <div className="row">
                  {inputsSecondRow.map((input) => (
                    <FormInput 
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange} 
                    />
                  ))}
                  <div className='phoneInput'>
                    <label>Phone</label>
                    <PhoneInput
                        disableCountryCode={false}
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        key="tel"
                        required
                        country={'ca'}
                        enableAreaCodes={false}
                        value={values.tel}
                        onChange={(value, country, e) => onChangePhone(value, country, e)}
                      />
                  </div>
                  <div className="userType">
                    <label>How can we help you?</label>
                    <select name="helpSelect" onChange={onChange} defaultValue="I have a general inquiry">
                      <option value="I have a general inquiry">I have a general inquiry</option>
                      <option value="I am looking for Remote Solutions">I am looking for Remote Solutions</option>
                      <option value="I am looking to join the Team">I am looking to join the Team</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="explanation">
                    <label>Message</label>
                    <textarea 
                      name="message"
                      type="text"
                      placeholder="Message"
                      required
                      pattern={`^.{1,}$`}
                      rows={5}
                      cols={10}
                      onChange={onChange}
                    />
                  </div>
              <div className="buttons">
                <button className="submit">SUBMIT</button>
              </div>
            </form>
            <div className='location'>
              <MapComp location={address}></MapComp>
            </div>
          </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    </div>
  )
}

export default ContactPage