import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import '../../../src/header.css'
import ShareIcon from '@mui/icons-material/Share';
import './shareDialog.css'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Tooltip from '@mui/material/Tooltip';
import Clipboard from '@react-native-clipboard/clipboard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareDialog({ buttonText }) {
  const [open, setOpen] = React.useState(false);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTooltipOpen(false);
  };

  const handleClickCopyButton = () =>
  {
    if(window.innerWidth < 768)
      Clipboard.setString(window.location.href)
    else
      navigator.clipboard.writeText(window.location.href)
    handleTooltipOpen();
  }

  return (
    <div>
      <button className="header-button" onClick={handleClickOpen}>
        <span className="share-icon">
            <ShareIcon style={{marginBottom: '2.5px', marginRight: '5px'}}></ShareIcon>
        </span>{buttonText}
      </button>
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='dialog-title'>{"Link of the job position"}</DialogTitle>
        <DialogContent>
          <input className='link-textfield' type="text" value={document.baseURI}/>
          <Tooltip 
            PopperProps={{
                disablePortal: true,
            }}
            onClose={handleTooltipClose}
            arrow 
            placement="top-start" 
            title='Copied!' 
            open={tooltipOpen} 
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onChangeCapture='Copied'>
            <button className='copy-button' onClick={handleClickCopyButton}><InsertLinkIcon/>
            </button>
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <button className='close-button' onClick={handleClose}>CLOSE</button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
