import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React  from 'react';
import './App.css';
import Footer from './footer/Footer';
import DynamicNavbar from './DynamicNavbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import HomePage from './pages/HomePage';
import ContactPage from './pages/contact/ContactPage';
import AboutPage from './pages/AboutPage';
import OurTeamPage from './pages/OurTeamPage';
import SolutionsPage from './pages/SolutionsPage';
import CareersPage from './pages/CareersPage';
import ApplicationPage from './pages/application/ApplicationPage';
import { useRef } from 'react';

function App() {

  const onSiteRef = useRef(null)

  const remoteRef = useRef(null)
  
  const headerTexts = {
    'general': "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",

    'contactPage': "Have any questions or want to get in touch? We’d love to hear from you."
  }

  const headerButtonTexts = {
    'general': 'LEARN MORE',
    'contactPage': 'GET IN TOUCH'
  }

  return (
    <Router> 
      <div className="App">
        <DynamicNavbar onSiteRef={onSiteRef} remoteRef={remoteRef}></DynamicNavbar>
        <Routes>
          <Route path='/' index element={<HomePage headerText={headerTexts['general']} headerButtonText={headerButtonTexts['general']}/>} >
          </Route>
          <Route path='/about' index element={<AboutPage headerText={headerTexts['general']} headerButtonText={headerButtonTexts['general']}/>} ></Route>
          <Route path='/our%20team' index element={<OurTeamPage headerText={headerTexts['general']} headerButtonText={headerButtonTexts['general']}/>} ></Route>
          <Route path='/solutions' index element={<SolutionsPage headerText={headerTexts['general']} headerButtonText={headerButtonTexts['general']}/>} ></Route>
          <Route path='/careers/:onlyCareerClicked' index element={<CareersPage isCareerPage={true} headerButtonText={headerButtonTexts['general']} onSiteRef={onSiteRef} remoteRef={remoteRef}/>} ></Route>
          <Route path='/contact' index element={<ContactPage headerText={headerTexts['contactPage']} headerButtonText={headerButtonTexts['contactPage']}/>} ></Route>
          
          
          <Route path='/application/:positionName/:location/:workingTime/:workingExperience/:buttonText' index element={<ApplicationPage/>}>
          </Route>
        </Routes>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
