import React from 'react'
import DynamicNavbar from '../../DynamicNavbar'
import Header from '../../Header'
import { useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ShareDialog from './ShareDialog'
import './application.css'
import { countryPhoneCodes } from '../../data/countryPhoneCodes'
import emailjs from 'emailjs-com'
import FormInputApplication from './forminput/FormInputApplication'
import PhoneInput from 'react-phone-input-2'
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Snackbar, Alert } from '@mui/material'
import { useForm, Controller } from "react-hook-form";

const ApplicationPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  

  const { nesto, control, reset } = useForm({
    defaultValues: {
      checkbox: false,
    }
  });

  const handleChangeFiles = (e) => {
    setValues({...values, ['files']: e.target.files})
    console.log(values)
  }

  const [values, setValues] = useState({
    name:"",
    lastName:"",
    email:"",
    address:"",
    city:"",
    state:"",
    tel:"",
    files: [],
    desiredSalary:""
  })

  const inputs1 = [
    {
      id:1,
      name:"name",
      type:"text",
      placeholder:"First Name",
      errorMessage:"Invalid input!",
      label:"First Name",
      required: true, 
      pattern: `^[A-Z][a-z ,.'-]+$`
    },
    {
      id:2,
      name:"lastName",
      type:"text",
      placeholder:"Last Name",
      errorMessage:"Invalid input!",
      label:"Last Name",
      required: true,
      pattern: `^[A-Z][a-z ,.'-]+$`
    },
    {
      id:3,
      name:"email",
      type:"email",
      placeholder:"Email",
      errorMessage:"It should be a valid email address!",
      label:"Email",
      required: true
    }]

    const inputs2 = [
      {
        id:4,
        name:"address",
        type:"text",
        placeholder:"Address",
        label:"Address",
        required: true
      },
      {
        id:5,
        name:"city",
        type:"text",
        placeholder:"City",
        label:"City",
        required: true
      },
      {
        id:6,
        name:"state",
        type:"text",
        placeholder:"State/Province",
        label:"State/Province",
        required: true
      }
    ]

  const [country, setCountry] = useState('');

  const [open, setOpen] = useState(false);

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  const onChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
    console.log(values)
  }

  const findPhoneCode = (country) =>
  {
    let phoneCode = ''
    for (let i of countryPhoneCodes)
    {
      if(i.name === country.name)
      {
        phoneCode = i.dial_code;
      }
    }
    return phoneCode;
  }

  const onChangePhone = (value, country, event) => {
    
    var phoneCode = findPhoneCode(country);

    setValues({
      ...values,
      [event.target.type]: event.target.value,
    });

    if(event.target.value === undefined)
    {
      setValues({
        ...values, tel: phoneCode,
      });
      console.log(values)
    }
  };

  const openNotification = () => {
    setOpen(true);
  };

  const openErrorNotification = () => {
    setOpenError(true);
  };

  const closeNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const closeErrorNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const handleSubmit = (e) => {
    setCountry(country)
    onChangePhone(values.tel, country, e)
    e.preventDefault();

    var fileSize = countFileSize()

    if(fileSize)
    {
      openErrorNotification() //error za file size
    }
      
    else
    {
      openNotification()
    
      emailjs.sendForm(`service_inyx9yg`, 'template_1mrtsgr', e.target, 'l8SVuixuQuYsO4nJs')
  
      setTimeout(function () {
        navigate('/')
      }, 2000);
    }
  }

  const Input = styled(MuiInput)`
  width: 42px;
`;

  const [value, setValue] = React.useState(1500);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  function valueLabelFormat(value) {
    values.desiredSalary = `${value} €`
    return `${value} €`
  }

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
    ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const countFileSize = () =>
  {
    var isExceeded = true;
    var memoryCount = 0;
    var uploadField = document.getElementById("file-upload")

    for (let file of uploadField.files)
    {
        console.log(file.size)
        memoryCount += file.size;
    }

    if(memoryCount < 512000)
      isExceeded = false
    return isExceeded;
  }

  return (
    <div style={{alignItems:'center'}}>
        <Stack spacing={2} sx={{ width: '100%' }} style={{backgroundColor: 'green'}}>
          <Snackbar open={open} autoHideDuration={2000} onClose={closeNotification}>
            <Alert onClose={closeNotification} severity="success" sx={{ width: '100%' }}>
              Email sent!
            </Alert>
          </Snackbar>
          <Snackbar open={openError} autoHideDuration={3500} onClose={closeErrorNotification}>
            <Alert onClose={closeErrorNotification} severity="error" sx={{ width: '100%' }}>
                The file has exceeded the memory limit
            </Alert>
          </Snackbar>
        </Stack>
        <Snackbar autoHideDuration={6000}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Email sent!
          </Alert>
        </Snackbar>
          <Grid item xs={12} sm={12} md={12} lg={12} className="application-form">
              <div className="formApplication">
                <h2>Join The Flock</h2>
                <form onSubmit={handleSubmit}>
                  <div className="formElementApplication">
                    <Grid container spacing={10}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {inputs1.map((input1) => (
                            <FormInputApplication 
                              key={input1.id}
                              {...input1}
                              value={values[input1.name]}
                              onChange={onChange} 
                            />
                          ))}
                          <div className='phoneInputApplication'>
                            <label>Phone</label>
                            <Controller
                                render={() => 
                                  <PhoneInput
                                    disableCountryCode={false}
                                    countryCodeEditable={true}
                                    enableLongNumbers={false}
                                    key="tel"
                                    borderRadius='0px'
                                    required
                                    country={'ca'}
                                    enableAreaCodes={false}
                                    value={values.tel}
                                    onChange={(value, country, e) => onChangePhone(value, country, e)}
                                    inputProps={{
                                      name: 'tel',
                                      borderRadius:'0px',
                                    }}
                                    searchStyle={{
                                      borderRadius: "0px !important" 
                                    }}
                                  />
                                }
                                name="tel"
                                control={control}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {inputs2.map((input2) => (
                            <FormInputApplication 
                              key={input2.id}
                              {...input2}
                              value={values[input2.name]}
                              onChange={onChange} 
                            />
                          ))}
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                              <div className='resume-slider-box'>
                                <div>
                                  <label style={{marginBottom:'10px'}} for="file-upload" class="custom-file-upload">
                                  Upload Resume (max 500KB)
                                  </label>
                                  <input name="files" id="file-upload" type="file" onChange={handleChangeFiles} multiple required/>
                                </div>
                                <div>
                                </div>
                            </div>
                          </Grid>
                      </Grid>
                      </Grid>
                      <div className="buttonsApplication" style={{textAlign: 'center'}}>
                        <button style={{marginTop:'50px'}} className="submitApplication">SUBMIT</button>
                      </div>
                  </div>
                </form>
              </div>
              </Grid>
        </div>
  )
}

export default ApplicationPage